import React from 'react';

import Seo from '../components/Seo';

import sections from '../constants/sections';

function AlawwalBank() {
  return <Seo title={sections.AlawwalBank.title} />;
}

export default AlawwalBank;
